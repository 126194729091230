import { Injectable } from '@angular/core';
import { UpdateFormValue } from '@ngxs/form-plugin';
import { RouterNavigation } from '@ngxs/router-plugin';
import { Action, Selector, SelectorOptions, State, StateContext, Store } from '@ngxs/store';
import { startOfDay } from 'date-fns';
import { normalizeToDate } from 'flex-app-shared';
import { switchMap, tap } from 'rxjs/operators';
import { DispatchingTableService, MarketPositionGridPoint } from '../../app/shared/dispatching-table/dispatching-table.service';
import { DispatchingScheduleToolbarFacade } from '../dispatching-schedule-toolbar-state/dispatching-schedule-toolbar.facade';
import {
  DispatchingTableActivate,
  DispatchingTableDeactivate,
  DispatchingTableGridPointsUpdatedEvent,
  DispatchingTableRefreshIfRequiredCommand
} from './dispatching-table.actions';
import { timer } from 'rxjs';
import { fakeAsync } from '@angular/core/testing';

class DispatchingTableStateModel {
  active = false;
  shouldReset = false;

  gridPoints: MarketPositionGridPoint[] = [];
  busy = false;
}

export const POSITION_REQUEST_DEBOUNCE_DELAY_MS = 50;

@State({
  name: 'dispatchingTable',
  defaults: new DispatchingTableStateModel()
})
@Injectable({
  providedIn: 'root'
})
@SelectorOptions({
  suppressErrors: false
})
export class DispatchingTableState {
  constructor(
    private store: Store,
    private service: DispatchingTableService,
    private toolbarFacade: DispatchingScheduleToolbarFacade
  ) {}

  @Selector([DispatchingTableState])
  static active(state: DispatchingTableStateModel): boolean {
    return state.active;
  }

  @Selector([DispatchingTableState])
  static busy(state: DispatchingTableStateModel): boolean {
    return state.busy;
  }

  @Selector([DispatchingTableState])
  static gridPoints(model: DispatchingTableStateModel): MarketPositionGridPoint[] {
    return model.gridPoints;
  }

  @Selector([DispatchingTableState])
  static isActive(model: DispatchingTableStateModel): boolean {
    return model.active;
  }

  isActive(): boolean {
    return this.store.selectSnapshot(DispatchingTableState.isActive);
  }

  @Action(DispatchingTableActivate)
  activate({ patchState, dispatch }: StateContext<DispatchingTableStateModel>): void {
    patchState({
      active: true
    });

    dispatch(new DispatchingTableRefreshIfRequiredCommand());
  }

  @Action(DispatchingTableDeactivate)
  deactivate({ patchState }: StateContext<DispatchingTableStateModel>): void {
    patchState({
      active: false,
      shouldReset: true
    });
  }

  @Action(RouterNavigation)
  routerInit({ getState, dispatch, setState }: StateContext<DispatchingTableStateModel>): any {
    if (!getState().active) {
      if (getState().shouldReset) {
        setState(new DispatchingTableStateModel());
      }
      return;
    }
    dispatch(new DispatchingTableRefreshIfRequiredCommand());
  }

  @Action(UpdateFormValue)
  handleUpdateFormValue({ getState, dispatch }: StateContext<DispatchingTableStateModel>): any {
    if (!getState().active) {
      return;
    }
    dispatch(new DispatchingTableRefreshIfRequiredCommand());
  }

  @Action(DispatchingTableRefreshIfRequiredCommand, { cancelUncompleted: true })
  handleDispatchingTableRefreshIfRequiredCommand({ patchState, dispatch }: StateContext<DispatchingTableStateModel>): any {
    if (!this.isActive()) {
      return;
    }

    // Only fetch if a customer is selected
    if (!this.toolbarFacade.getFilters().customerId) {
      return;
    }

    patchState({
      busy: true
    });

    const { selectedDate, gridPointId, customerId } = this.toolbarFacade.getFilters();

    return timer(POSITION_REQUEST_DEBOUNCE_DELAY_MS).pipe(
      switchMap(() => this.service.getPositions(customerId, startOfDay(normalizeToDate(selectedDate)), gridPointId)),
      tap({
        next: (result) => {
          patchState({
            busy: false
          });

          const statePairs = [];

          // Always push customerId
          statePairs.push(['customerId', customerId]);

          dispatch(new DispatchingTableGridPointsUpdatedEvent(result));
        },
        error: () => {
          patchState({
            busy: false
          });
          dispatch(new DispatchingTableGridPointsUpdatedEvent([]));
        }
      })
    );
  }

  @Action(DispatchingTableGridPointsUpdatedEvent)
  handleDispatchingTableGridPointsUpdatedEvent(
    { patchState }: StateContext<DispatchingTableStateModel>,
    { gridPoints }: DispatchingTableGridPointsUpdatedEvent
  ): any {
    patchState({
      gridPoints
    });
  }
}
