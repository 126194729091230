export class DispatchScheduleUpdateDataCommand {
  public static readonly type = '[Dispatch Schedule] Update data command';
}

export class DispatchScheduleActivate {
  public static readonly type = '[Dispatch Schedule] Activate';
}

export class DispatchScheduleDeactivate {
  public static readonly type = '[Dispatch Schedule] Deactivate';
}
