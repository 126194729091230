import { MarketPositionGridPoint } from '../../app/shared/dispatching-table/dispatching-table.service';

export class DispatchingTableActivate {
  public static type: string = '[Dispatching Table] Activate';
}

export class DispatchingTableDeactivate {
  public static type: string = '[Dispatching Table] Deactivate';
}

export class DispatchingTableRefreshIfRequiredCommand {
  public static type: string = '[Dispatching Table] Refresh if filters have changed';
}

export class DispatchingTableGridPointsUpdatedEvent {
  public static type: string = '[Dispatching Table] Grid points updated';

  constructor(public gridPoints: MarketPositionGridPoint[]) {}
}
