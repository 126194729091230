import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { isSameDay } from 'date-fns';
import { normalizeToDate } from 'flex-app-shared';
import { DispatchingScheduleToolbarState } from './dispatching-schedule-toolbar.state';

@Injectable({
  providedIn: 'root'
})
export class DispatchingScheduleToolbarFacade {
  constructor(public store: Store) {}

  busy$ = this.store.select(DispatchingScheduleToolbarState.busy);
  selectedDate$ = this.store.select(DispatchingScheduleToolbarState.selectedDate);

  getSelectedDate(): string {
    return this.getFilters().selectedDate;
  }

  isTodaySelected(): boolean {
    return this.getSelectedDate() && isSameDay(normalizeToDate(this.getSelectedDate()), new Date());
  }

  getFilters(): { selectedDate: string | null; customerId: string | null; gridPointId: string | null } {
    return this.store.selectSnapshot(DispatchingScheduleToolbarState.filters).model;
  }
}
