import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { DispatchingScheduleToolbarActivate, DispatchingScheduleToolbarDeactivate } from './dispatching-schedule-toolbar.actions';

@Injectable({ providedIn: 'root' })
export class ActivateDispatchingScheduleToolbarState {
  constructor(private store: Store) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.store.dispatch(new DispatchingScheduleToolbarActivate());
    return true;
  }
}

@Injectable({ providedIn: 'root' })
export class DeactivateDispatchingScheduleToolbarState {
  constructor(private store: Store) {}

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.store.dispatch(new DispatchingScheduleToolbarDeactivate());
    return true;
  }
}
