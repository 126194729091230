import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { format, parseISO } from 'date-fns';
import { Capacity, Direction, getRetryConfig, TimeSlot, XRangeChartPoint } from 'flex-app-shared';
import moment, { Moment } from 'moment';
import { forkJoin, Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DispatchScheduleService {
  constructor(private http: HttpClient) {}

  getNewDayAheadData(customerId: string, date: Date, gridPointId: string): Observable<DayAheadData> {
    let params = new HttpParams();
    if (gridPointId) params = params.set('gridPointId', gridPointId);
    params = params.set('day', format(date, 'yyyy-MM-dd'));

    const sources: Observable<any>[] = [
      this.http.get<DayAheadData>(`/api/v1/dispatch-schedules/${customerId}/day-ahead`, { params }),
      this.http.get<PulseData>(`/api/v1/dispatch-schedules/${customerId}/pulses`, { params }),
      this.http.get<HeatBufferData>(`/api/v1/dispatch-schedules/${customerId}/heat-buffer-states`, { params }),
      this.http.get<ImbalancePrices>(`/api/v1/dispatch-schedules/imbalance-prices`, { params }),
      this.http.get<DayAheadPrices>(`/api/v1/dispatch-schedules/day-ahead-prices`, { params }),
      this.http.get<DealVolumesData>(`/api/v1/dispatch-schedules/${customerId}/deal-volumes`, { params }),
      this.http.get<ImbalancePricesAndForecast>(`/api/v1/dispatch-schedules/imbalance-prices-forecast`, { params })
    ];

    return forkJoin(sources.map((source) => source.pipe(retry(getRetryConfig())))).pipe(
      map(
        ([dayAheadData, pulseData, heatBufferData, imbalancePrices, dayAheadPrices, dealVolumesData, imbalancePricesAndForecast]: [
          DayAheadData,
          PulseData,
          HeatBufferData,
          ImbalancePrices,
          DayAheadPrices,
          DealVolumesData,
          ImbalancePricesAndForecast
        ]) => {
          return {
            ...dayAheadData,
            pulses: pulseData.pulsesInKWhPer5Minutes,
            pulseStartDateTime: parseISO(pulseData.firstMinute),
            heatBufferData,
            pricesStartDateTime: moment(imbalancePrices.firstMinute),
            imbalancePrices,
            dayAheadPrices,
            upwardsPrices: imbalancePrices.upwardsPrices,
            downwardsPrices: imbalancePrices.downwardsPrices,
            intraDayDealVolumes: dealVolumesData.intraDayDealVolumes,
            dayAheadDealVolumes: dealVolumesData.dayAheadDealVolumes,
            aggregatedDealVolumes: dealVolumesData.aggregatedDealVolumes,
            tennetDispatchPriceStartDateTime: parseISO(imbalancePricesAndForecast.prices.firstMinute),
            tennetDispatchPriceUpwards: imbalancePricesAndForecast.prices.upwardsPrices,
            tennetDispatchPriceDownwards: imbalancePricesAndForecast.prices.downwardsPrices,
            forecastPriceStartDateTime: parseISO(imbalancePricesAndForecast.forecast.firstMinute),
            forecastPriceConsumption: imbalancePricesAndForecast.forecast.upwardsPrices,
            forecastPriceConsumptionExtrapolationData: imbalancePricesAndForecast.forecast.upwardsPricesExtrapolationData,
            forecastPriceProduction: imbalancePricesAndForecast.forecast.downwardsPrices,
            forecastPriceProductionExtrapolationData: imbalancePricesAndForecast.forecast.downwardsPricesExtrapolationData
          };
        }
      )
    );
  }

  getOnOffData(customerId: string, date: Date, gridPointId?: string): Observable<OnOffData> {
    let params = new HttpParams();
    if (gridPointId) params = params.set('gridPointId', gridPointId);
    params = params.set('day', format(date, 'yyyy-MM-dd'));

    return this.http.get<OnOffData>(`/api/v1/dispatch-schedules/${customerId}/dispatch-schedule`, { params }).pipe(retry(getRetryConfig()));
  }
}

export interface PulseData {
  customerId: string;
  gridPointIds: ReadonlyArray<string>;
  day: string;
  pulsesInKWhPer5Minutes: ReadonlyArray<number>;
  firstMinute: string;
}

export interface CurrentReading {
  time: string;
  type: string;
  value: number;
}

export interface HeatBufferData {
  customerId: string;
  day: string;
  states: ReadonlyArray<HeatBufferState>;
  firstMinute: string;
}

export interface HeatBufferState {
  gridPointId: string;
  heatBufferId: string;
  heatBufferDescription: string;
  averageStatePerMinute: ReadonlyArray<number>;
}

export interface ImbalancePrices {
  day: string;
  firstMinute: string;
  upwardsPrices: ReadonlyArray<number>;
  upwardsPricesExtrapolationData: ExtrapolationData[];
  downwardsPrices: ReadonlyArray<number>;
  downwardsPricesExtrapolationData: ExtrapolationData[];
}

export interface ImbalancePricesAndForecast {
  prices: ImbalancePrices;
  forecast: ImbalancePrices;
}

export interface DayAheadPrices {
  day: string;
  prices: ReadonlyArray<DayAheadPrice>;
}

export interface DayAheadPrice {
  period: TimeSlot;
  price: number;
}

export interface ExtrapolationData {
  /**
   * Y level to start the left side vertical line at
   */
  valueFrom: number | undefined;

  /**
   * Y level for the main horizontal line
   */
  value: number;

  /**
   * Left X value
   */
  startDateTime: Date;

  /**
   * Right X value
   */
  toDateTime: Date;
}

export interface DayAheadData {
  customerId: string;
  gridPointIds: ReadonlyArray<string>;
  day: string;
  aggregatedDispatchSchedule: ReadonlyArray<AggregatedDispatchSchedule>;
  pulses: ReadonlyArray<number>;
  pulseStartDateTime: Moment | Date;
  heatBufferData: HeatBufferData;
  heatBufferStartDateTime: Moment;
  currentReading?: CurrentReading;
  pricesStartDateTime: Moment;
  upwardsPrices: ReadonlyArray<number>;
  downwardsPrices: ReadonlyArray<number>;
  intraDayDealVolumes: ReadonlyArray<DealVolume>;
  dayAheadDealVolumes: ReadonlyArray<DealVolume>;
  aggregatedDealVolumes: ReadonlyArray<DealVolume>;
  tennetDispatchPriceStartDateTime: Date;
  tennetDispatchPriceUpwards: ReadonlyArray<number>;
  tennetDispatchPriceDownwards: ReadonlyArray<number>;
  forecastPriceStartDateTime: Moment | Date;
  forecastPriceConsumption: ReadonlyArray<number>;
  forecastPriceConsumptionExtrapolationData: ExtrapolationData[];
  forecastPriceProduction: ReadonlyArray<number>;
  forecastPriceProductionExtrapolationData: ExtrapolationData[];

  imbalancePrices?: ImbalancePrices;
}

export interface DealVolumesData {
  customerId: string;
  gridPointIds?: ReadonlyArray<string>;
  day: string;
  intraDayDealVolumes: ReadonlyArray<DealVolume>;
  dayAheadDealVolumes: ReadonlyArray<DealVolume>;
  aggregatedDealVolumes: ReadonlyArray<DealVolume>;
}

export interface DealVolume {
  day: DealDay | string;
  period: TimeSlot;
  power: Capacity;
}

export interface DealDay {
  day: string;
  timeZone: string;
}

export interface AggregatedDispatchSchedule {
  day: string;
  period: AggregatedPeriod;
  power: AggregatedPower;
}

export interface AggregatedPeriod {
  startDateTime: string;
  toDateTime: string;
}

export interface AggregatedPower {
  value: number;
  unit: string;
}

export interface OnOffData {
  customerId: string;
  customerName: string;
  day: string;
  controls: ReadonlyArray<OnOffControl>;
}

export interface OnOffControl {
  controlId: string;
  controlDescription: string;
  states: ReadonlyArray<OnOffControlState>;
  controlDirection: Direction;
  gridPointIds: string[];
}

export interface OnOffControlWithGridPoint extends OnOffControl {
  // Description of the gridPoints referenced by gridPointIds (if available)
  gridPointsDescription: string | null;
}

export interface OnOffControlState {
  period: TimeSlot;
  state: SwitchState | any;
  reason: Reason | any;
  mandatory: any;
}

export enum SwitchState {
  ON = 'ON',
  OFF = 'OFF',
  NO_SWITCH = 'NO_SWITCH'
}

export enum Reason {
  TSO_ACTIVATION = 'TSO_ACTIVATION',
  IMBALANCE = 'IMBALANCE',
  INTRA_DAY = 'INTRA_DAY',
  REGULAR = 'REGULAR'
}

export interface DispatchScheduleXRangeChartPoint extends XRangeChartPoint {
  switchState?: SwitchState;
  reason?: Reason;
  controlId?: string;
}
