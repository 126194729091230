import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Capacity, TimeSlot } from 'flex-app-shared';
import { Observable } from 'rxjs';
import { format } from 'date-fns';

export enum MarketPositionSource {
  INTRADAY = 'intraday',
  DAY_AHEAD = 'day-ahead',
  TOTAL = 'total',
  SUB_TOTAL = 'subtotal'
}

export class MarketPosition {
  period: TimeSlot;
  position: Capacity;
  source: MarketPositionSource;
}

export class MarketPositionControl {
  description: string;
  id: string;
  markets: {
    description: string;
    marketPositions: MarketPosition[];
  }[];
}

export class MarketPositionGridPoint {
  id: string;
  description: string;

  consumption: {
    controls: MarketPositionControl[];
    totals: [];
  };
  production: {
    controls: MarketPositionControl[];
    totals: MarketPosition[];
  };
  totals: MarketPosition[];
}

@Injectable({
  providedIn: 'root'
})
export class DispatchingTableService {
  uri = '/api/v1/day-trading/position';

  constructor(private http: HttpClient) {}

  getPositions(customerId: string, day: Date, gridPointId?: string): Observable<MarketPositionGridPoint[]> {
    let params = new HttpParams();
    if (gridPointId) params = params.set('gridPointId', gridPointId);
    params = params.set('day', format(day, 'yyyy-MM-dd'));
    return this.http.get<MarketPositionGridPoint[]>(`${this.uri}/${customerId}`, { params });
  }
}
